import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

const FAQ = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="FAQ">
        <section className="content-header content-header-1 -blue">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 className="heading">Frequently Asked Questions</h1>
            </div>
        </div>
    </div>
</section>

<section className="content content-2 -bg-grey">
    <div className="container">

        <div className="row">

            <div className="col-md-12">
                    <h3 className="-no-margin-top">What is DJ Manager?</h3>
                    <p>DJ Manager is a hosted web application created from the ground up to be a powerful base for your business. Located in a secure, scalable web environment and built using over 7 years of industry experience, you can let us sweat the small stuff and focus on what matters.</p>

                    <h3 className="-no-margin-top">How does the software work?</h3>
                    <p>DJ Manager is custom built from the group up to ensure you get more than just a simple calendar. By developing features in house to custom specifications, we are able to ensure that the software meets both the requirements for functionality and efficiency. Your data is stored within our database layer and your application interacts with this data storage to serve the pages to you.</p>

                    <h3 className="-no-margin-top">How regular are updates?</h3>
                    <p>We operate on a bi-weekly release cycle for new features. Before updates are applied, testing is carried out in an isolated staging environment, to identify any potential issues.</p>
                    <p>Our robust development and deployment stategies also allow us to perform minor fixes outside of this schedule and roll them out across our fleet in a rolling-update fashion.</p>

                    <h3 className="-no-margin-top">How reliable is the software?</h3>
                    <p>Reliability is one of the most important factors when choosing an online solution for your business, not just for yourself but also for your clients.</p>
                    <p>Incremental backups of the database are taken every hour, and prior to software update rollouts. Restoration procedures are in place and are tested regularly.</p>
                    <p>We operate the software in a highly-available cluster of servers, so that we can tolerate the failure of multiple nodes and still be fully operational. Servers are configured to a custom specification by us and we can increase capacity and availibility in a matter of minutes, should we need it.</p>
                    <p>This highly-available system means we can perform server maintenance with zero downtime to you.</p>

                    <h3 className="-no-margin-top">Who develops the software?</h3>
                    <p>The lead developer is Dan Coleman, a software developer by trade, who holds a degree in Internet and Computing Technologies.</p>
                    <p>He has extensive experience in industry systems, including planning and implementation of software hosting systems, database management, e-commerce services and more.</p>
                    <p>A continuity plan is also in place, so that the development, maintenance and day-to-day running of DJ Manager can continue should anything happen to him.</p>               

                    
            </div>

        </div>

    </div>
</section>

<section className="cta cta-1 -inverse">
    <div className="container">
        <div className="row">

            <div className="col-lg-10 col-lg-offset-1">
                <h2 className="heading"><strong>Got a question about the software?</strong> <br /> <small>Give us a message and we will be happy to answer.</small></h2>
                <a href="/contact-us/"><button className="btn flat-btn -blue">Contact Us</button></a>
            </div>

        </div>
    </div>
</section>
        </WebsiteLayout>
    </main>
  )
}

export default FAQ;